import { Injectable } from '@angular/core';
import {DeviceRepo} from "../repo/device.repo";
import {BehaviorSubject, catchError, Observable, of, tap} from "rxjs";
import {Device} from "../model/Device";

@Injectable({
  providedIn: 'root'
})
export class DevicestoreService {
  private devicesSubject = new BehaviorSubject<Device[]>([]);
  devices$: Observable<Device[]> = this.devicesSubject.asObservable();
  constructor(private repo: DeviceRepo) { }


  getDevices(): void {
    this.repo.getDevices().pipe(
      tap((data) => {
        this.devicesSubject.next(data);
      }),
      catchError((error) => {
        console.error('Error fetching devices:', error);
        return of([]);
      })
    ).subscribe();
  }




}
