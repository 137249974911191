<div class="dialog-container">
  <h2 mat-dialog-title class="dialog-title">
    Bestätigung
  </h2>
  <mat-dialog-content class="dialog-content">
    <p class="dialog-message">
      Sind Sie sicher, dass Sie die Erstellung des Change Requests abbrechen möchten?
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="dialog-actions">
    <button mat-fab extended (click)="onCancelClick()" class="cancel-button">Abbrechen</button>
    <button mat-fab extended color="warn" (click)="onConfirmClick()" class="confirm-button">Bestätigen</button>
  </mat-dialog-actions>
</div>
