import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {Device} from "../model/Device";

@Injectable({
  providedIn: 'root'
})
export class DeviceRepo {

  constructor(private http: HttpClient) { }


  getDevices(): Observable<Device[]> {
    return this.http.get<Device[]>(environment.api.baseUrl + "/devices", {withCredentials:true});
  }




}
