export enum FieldKeys {
  Subject = 'subject',
  Owner = 'owner',
  ChangeRequestDescription = 'Change Request Description',
  Requestor = 'requestor',
  Due = 'due',
  Expire = 'expire',
  Device = 'devices'
}

export enum CustomFieldKeys {
  RequestedSourceGroupName = 'Requested Source Group Name',
  RequestedDestinationGroupName = 'Requested Destination Group Name',
  RequestedServiceGroupName = 'Requested Service Group Name',
  AccessLists = 'Access Lists',
  RequestorGroup = 'requestorGroup'
}


export enum TemplateName {
  Standard = 'Standard',
  FWRegelErstellen = 'FW Regel erstellen',
  FWRegelLoeshen = 'FW Regel löschen',
  FWRegelAendern = 'FW Regel ändern'
}
